import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import subMonths from 'date-fns/subMonths';

import { SORT_BY_USER } from '../../constants/common';
import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { OK } from '../../constants/httpStatusCodes';
import { LIST_SIZE_PER_PAGE_25 } from '../../constants/values';
import axios from '../../services/axios';
import { getSchemaParams, parseToMonthInt } from '../../utils/helpers';

const date = new Date();
const prevMonth = subMonths(date, 1);
const month = parseToMonthInt(prevMonth.getFullYear(), prevMonth.getMonth() + 1);

export const initialState = {
  isLoading: false,
  isAddLoading: false,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      user: {
        id: [],
        search: '',
      },
      project: {
        id: [],
        search: '',
      },
      department: {
        id: [],
        search: '',
      },
      lead: {
        id: [],
        search: '',
      },
      month,
      show_only_with_positive_gross_margin: false,
    },
    with: ['project'],
    sort: SORT_BY_USER,
    page: null,
    per_page: LIST_SIZE_PER_PAGE_25,
  },
  tableFilters: {
    userName: '',
    projectName: '',
  },
  idsToSubmit: [],
  fieldsWithErrors: [],
};

export const fetchTechleadingRecommended = createAsyncThunk(
  'techleadingRecommended/fetchTechleadingRecommended',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { query } = getState().techleadingRecommended;
      const { data, status } = await axios.get('/user_teachleads/recommended', { params: query });

      const newData = data.data.map((item) => {
        return {
          ...item,
          id: nanoid(),
          rate: getSchemaParams(item),
        };
      });

      const resData = { ...data, data: newData };

      return status === OK ? resData : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchNextPageIfNeeded = createAsyncThunk(
  'techleadingRecommended/fetchNextPageIfNeeded',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { query } = getState().techleadingRecommended;

      const { data, status } = await axios.get('/user_teachleads/recommended', {
        params: { ...query, page: query.page + 1 },
      });

      if (status === OK) {
        return {
          ...data,
          data: data.data[0]
        };
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addTechleading = createAsyncThunk(
  'techleadingRecommended/addTechleading',
  async ({ id }, { dispatch, rejectWithValue, getState }) => {
    try {
      const { data: recommendedData, query, meta } = getState().techleadingRecommended;

      const itemToAdd = recommendedData.find((item) => item.id === id);

      const body = {
        month: query.filters.month,
        user_id: itemToAdd.user.id,
        project_id: [itemToAdd.project.id],
        lead_id: itemToAdd.lead?.id,
        department_id: itemToAdd.department.id,
      };

      const { data, status } = await axios({
        method: httpRequestMethods.POST,
        url: '/user_teachleads',
        data: body,
      });

      if (status === httpStatusCodes.OK || status === httpStatusCodes.CREATED) {
        if (meta.current_page < meta.last_page) {
          dispatch(fetchNextPageIfNeeded());
        }
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const techleadingRecommendedSlice = createSlice({
  name: 'techleadingRecommended',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
      state.query.page = null;
    },
    setTableFilters: (state, action) => {
      state.tableFilters = { ...state.tableFilters, ...action.payload };
    },
    setSort: (state, action) => {
      state.query.sort = action.payload;
    },
    setTableHeadData: (state, action) => {
      state.tableHeadData = action.payload.tableHeadData;
    },
    setLeadData: (state, action) => {
      const findIndex = state.data.findIndex((item) => item.id === action.payload.id);

      if (findIndex >= 0) {
        state.data[findIndex].lead = action.payload.lead;
      }
    },
    resetErrorFields: (state, action) => {
      state.fieldsWithErrors = state.fieldsWithErrors.filter((id) => id !== action.payload.id);
    },
    setAddLoading: (state, action) => {
      state.isAddLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTechleadingRecommended.pending, (state) => {
        state.isLoading = true;
        state.fieldsWithErrors = initialState.fieldsWithErrors;
      })
      .addCase(fetchTechleadingRecommended.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchTechleadingRecommended.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchNextPageIfNeeded.pending, (state) => {
        state.isAddLoading = true;
      })
      .addCase(fetchNextPageIfNeeded.fulfilled, (state, action) => {
        state.data = [...state.data, action.payload.data];
        state.meta = action.payload.meta;
      })
      .addCase(fetchNextPageIfNeeded.rejected, (state) => {
        state.isAddLoading = false;
      });
    builder
      .addCase(addTechleading.pending, (state, action) => {
        state.isAddLoading = true;
        state.idsToSubmit = [...state.idsToSubmit, action.meta.arg.id];
      })
      .addCase(addTechleading.fulfilled, (state, action) => {
        state.isAddLoading = false;
        state.idsToSubmit = state.idsToSubmit.filter((id) => id !== action.meta.arg.id);
        state.data = state.data.filter((item) => item.id !== action.meta.arg.id);
      })
      .addCase(addTechleading.rejected, (state, action) => {
        state.isAddLoading = false;
        state.idsToSubmit = state.idsToSubmit.filter((id) => id !== action.meta.arg.id);
        state.error = action.payload;
        const itemToAdd = state.data.find((item) => item.id === action.meta.arg.id);

        if (!itemToAdd.lead?.id) {
          state.fieldsWithErrors = [...state.fieldsWithErrors, action.meta.arg.id];
        }
      });
  },
});

export const {
  resetState,
  setQuery,
  setQueryFilter,
  setSort,
  setTableFilters,
  setLeadData,
  resetErrorFields,
  setAddLoading
} = techleadingRecommendedSlice.actions;

export default techleadingRecommendedSlice.reducer;
