import PropTypes from 'prop-types';

import { USER_STATUSES } from '../../constants/status';
import Avatar from '../Avatar/Avatar';
import Badge from '../Badge/Badge';
import Vacation from '../Badge/Vacation';

const UserBadge = ({
  user,
  width = 24,
  height = 24,
}) => (
  <Badge statuses={user?.on_vacation ? (<Vacation vacation="vacation" />) : undefined}>
    <Avatar
      width={width}
      height={height}
      image={user.photo48_link || '/images/avatar.svg'}
      alt={user.name}
    />
  </Badge>
);

UserBadge.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.oneOf(USER_STATUSES),
    photo48_link: PropTypes.string,
    on_vacation: PropTypes.bool,
  }).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default UserBadge;
